<template>
  <LoginUser @inst-event="installPWA" v-if="!loggedIn"></LoginUser>
  <div v-if="loggedIn">
  <ChurchLogo v-if="logoModal" @logo-event="updateLogo" @close-logo="closeLogoModal"></ChurchLogo>
  <BackgroundImages v-if="backgroundModal" @child-event="updateMonitorBackground"
                    @close-media="closeMediaModal"></BackgroundImages>
  <div v-if="message" id="myModal" class="fixed inset-0 z-50 flex items-center justify-center  bg-black bg-opacity-80">
    <!-- Modal content -->
    <div style="width:50%; background: #1b1e21; padding: 8px; padding-bottom: 19px">
      <div class="flex justify-between items-center mb-4 px-6 py-3 bg-gray-800 text-white">
        <h2 class="text-lg font-bold">Quick Announcement</h2>
        <button @click="message=false" class="text-gray-400 hover:text-gray-200 focus:outline-none">
          <svg class="h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 18L18 6M6 6l12 12" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
      <div class="mb-6">
        <textarea v-model="information" id="lyrics" name="lyrics"
                  class="my_input shadow appearance-none border rounded w-full py-2 px-3 text-gray-400 leading-tight focus:outline-none focus:shadow-outline"
                  style="height: 351px"></textarea>
      </div>
      <div class="text-right">
        <button @click="stopInformation()"
                class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline rounded-sm text-xs"
                type="button">
          Stop scroll message <i class="fa fa-close"></i>
        </button>
        &nbsp;
        <button @click="setInformation()"
                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline rounded-sm text-xs"
                type="button">
          Post <i class="fa fa-mail-forward"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="home">
    <main>
      <div class="main-container">
        <div class="top-section">
<!--          <div class="top-section-first-box">-->
<!--            <div class="top-section-first-box-header">-->
<!--              <div class="top-section-first-box-header-main">-->
<!--                <h5 @click="installPWA">Schedule</h5>-->
<!--                <span class="icon">-->
<!--                  <span class="app"-->
<!--                  ><i class="fa fa-th-large" aria-hidden="true"></i-->
<!--                  ></span>-->
<!--                  <span-->
<!--                  ><i class="fa fa-angle-down" aria-hidden="true"></i-->
<!--                  ></span>-->
<!--                </span>-->
<!--              </div>-->

<!--              <div class="top-section-first-box-header-sub">-->
<!--                <span><i class="fa fa-cog" aria-hidden="true"></i></span>-->
<!--                <span><i class="fa fa-angle-down" aria-hidden="true"></i></span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="top-section-first-box-body">-->
<!--              &lt;!&ndash; First box content  &ndash;&gt;-->
<!--            </div>-->
<!--          </div>-->
          <div class="top-section-second-box">
            <div class="top-section-second-box-header">
              <h5 style="font-family: 'Segoe UI, Arial, sans-serif',serif" v-if="previewing">Preview -
                {{ previewing }}</h5>
<!--              <span class="icon-box">-->
<!--                <span class="menu">-->
<!--                  <i class="fa fa-sliders" aria-hidden="true"></i>-->
<!--                </span>-->
<!--                <span><i class="fa fa-angle-down" aria-hidden="true"></i></span-->
<!--                ></span>-->
            </div>
            <div class="top-section-second-box-body">
              <div v-for="(preview, index) in previews" :key="preview.id" class="
              top-section-second-box-body-box chorus-box"
                   :class="{ 'verse-box': preview === screenDisplay }"
              >
                <div class="number-box">
                  <p>{{ index + 1 }}</p>
                  <span><i class="fa fa-music" aria-hidden="true"></i></span>
                </div>
                <div @click="screen(preview)" class="text-box">
                  <ul>
                    <li class="title">Slide {{ index + 1 }}</li>
                    <li><p style="white-space: pre-line; font-family: 'Segoe UI, Arial, sans-serif',serif"
                           v-html="preview"></p></li>
                  </ul>
                </div>
              </div>

              <div v-if="!previews.length" class="top-section-second-box-body-box verse-box">
                <div class="number-box">
                  <p>1</p>
                  <span><i class="fa fa-music" aria-hidden="true"></i></span>
                </div>
                <div class="text-box">
                  <ul style=" font-family: 'Segoe UI, Arial, sans-serif',serif;">
                    <li class="title">Preview</li>
                    <li>All your previews will show up here.</li>
                    <li>Simple search for a song and double click,</li>
                    <li>Enjoy worship light,</li>
                    <li>We give all glory to god.</li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
          <div class="top-section-third-box">
            <div class="top-section-third-box-header">
              <h5 style="font-family: 'Segoe UI, Arial, sans-serif',serif">Live</h5>
<!--              <span class="icon-box">-->
<!--                <span class="menu">-->
<!--                  <i class="fa fa-sliders" aria-hidden="true"></i>-->
<!--                </span>-->
<!--                <span><i class="fa fa-angle-down" aria-hidden="true"></i></span-->
<!--                ></span>-->
            </div>
            <div :style="{ 'background-image': 'url(' + background + ')' }" class="top-section-third-box-body monitor">
              <img style="position: fixed;" v-if="selectedLogo" width="40" :src="selectedLogo" alt="Logo" class="">
              <br/><br/>
              <p style="height: 83%; text-align: center; color: white; white-space: pre-line; font-size: 19px; font-family: 'Segoe UI, Arial, sans-serif',serif" v-html="screenDisplay"></p>
              <div v-if="information" style="background: blue; height: 32px">
              <marquee  behavior="scroll" direction="left" style="color: #fff; font-size: 20px;font-family: 'Segoe UI, Arial, sans-serif',serif">
                {{ information }}
              </marquee>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-section">
          <div class="bottom-section-header">
            <ul class="bottom-section-header-menu">
              <li>
                <button :class="{ 'active': panel === 'songs' }" @click="panel= 'songs'">Songs</button>
              </li>
              <li>
                <button :class="{ 'active': panel === 'scriptures' }" @click="panel= 'scriptures'">Scriptures</button>
              </li>
<!--              <li>-->
<!--                <button>Presentations</button>-->
<!--              </li>-->
<!--              <li>-->
<!--                <button>Themes</button>-->
<!--              </li>-->
            </ul>
            <span class="bottom-section-header-icons">
               <span style="cursor: pointer;">
                 <p title="Count down timer"  aria-hidden="true">Power Points</p>
               </span>

              <span style="cursor: pointer;">
                 <p title="Count down timer" @click="logoModal = true" aria-hidden="true">Logos</p>
               </span>

               <span style="cursor: pointer;">
                 <p title="Show Media Files" @click="backgroundModal = true" aria-hidden="true">Images</p>
               </span>

               <span style="cursor: pointer;">
                 <i title="Clear Text" class="fa fa-close" aria-hidden="true" @click="clearScreen"></i>
               </span>

               <span style="cursor: pointer;">
                 <i title="Quick scroll message" class="fa fa-mail-forward" @click="message = true"
                    aria-hidden="true"></i>
               </span>

               <span style="cursor: pointer;">
                 <i title="Reduce text font-size" class="fa fa-minus" @click="decreaseFontSize()"
                    aria-hidden="true"></i>
               </span>

               <span style="cursor: pointer;">
                 <i title="Increase text font-size" class="fa fa-plus" @click="increaseFontSize()"
                    aria-hidden="true"></i>
               </span>

               <span style="cursor: pointer;">
                 <i title="Show Camera" class="fa fa-camera"
                    :style="{ color: backgroundType === 'cameraDisplay' ? 'red' : 'white' }" @click="toggleCamera()"
                    aria-hidden="true"></i>
               </span>

               <span style="cursor: pointer;">
                 <i title="Dock Display" class="fa fa-tv" @click="openScreen1()" aria-hidden="true"></i>
               </span>

              <span style="cursor: pointer;">
                <i  class="fa fa-play" aria-hidden="true"></i>
              </span>

            </span>
          </div>
        </div>
        <SongsPanel v-if=" panel === 'songs'" @child-event="pushToSchedule"/>
        <BiblePanel v-if=" panel === 'scriptures'" @showBibleText="screen"></BiblePanel>
      </div>
    </main>

  </div>
  </div>
</template>

<script>
import SongsPanel from "@/components/SongsPannel.vue";
import BiblePanel from "@/components/BiblePannel.vue";
import BackgroundImages from "@/components/BackgroundImages.vue";
import ChurchLogo from "@/components/ChurchLogo.vue";
import LoginUser from "@/components/LoginUser.vue";
import AppService from "@/Services/AppService";


export default {
  name: 'HomeView',
  components: {LoginUser, ChurchLogo, BackgroundImages, BiblePanel, SongsPanel},
  props: ['token'],
  data() {
    return {
      loggedIn: false,
      endpoint: AppService.token,
      panel: 'songs',
      backgroundModal: false,
      logoModal: false,
      background: require('@/assets/bb.jpg'),
      schedules: [],
      message: false,
      action: 1,
      backgroundType: null,
      songManager: 1,
      screenDisplay: null,
      showing: null,
      previewing: null,
      information: null,
      errorMessage: null,
      previews: [],
      setUpdateLyricsId: null,
      selectedScheduleId: null,
      lowerMonitor: null,
      selectedLogo: null,
      speak: false,
      createAuthor: null,
      createLyrics: null,
      createTitle: null,
      updateAuthor: null,
      updateLyrics: null,
      updateTitle: null,
      selectedBook: null,
      scriptures: [],
      deferredPrompt: null,
      showInstallButton: false

    }
  },

  mounted() {
    window.addEventListener('beforeinstallprompt', this.handleInstallPrompt);
    // Retrieve font size from local storage on component mount
    let font = 58;
    localStorage.setItem('font', font.toString());
    localStorage.setItem('displayType', 'imageBackground');
    this.backgroundType = 'imageBackground';
  },

  created() {
    // Call the method initially
    this.updateLoggedInStatus();

    // Set up an interval to call the method every second
    this.intervalId = setInterval(() => {
      this.updateLoggedInStatus();
    }, 1000);
  },

  beforeUnmount() {
    // Clear the interval when the component is destroyed to prevent memory leaks
    window.removeEventListener('beforeinstallprompt', this.handleInstallPrompt);
    clearInterval(this.intervalId);
  },

  methods: {
    handleInstallPrompt(event) {
      // Prevent the default behavior
      event.preventDefault();

      // Store the event for later use
      this.deferredPrompt = event;

      // Update component state to show the install button
      this.showInstallButton = true;
    },
    installPWA() {
      if (this.deferredPrompt) {
        // Show the installation prompt
        this.deferredPrompt.prompt();

        // Wait for the user to respond to the prompt
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the install prompt');
            // Optionally track installation
          } else {
            console.log('User dismissed the install prompt');
          }

          // Reset the deferredPrompt
          this.deferredPrompt = null;
        });

        // Hide the install button
        this.showInstallButton = false;
      }
    },
    updateLoggedInStatus() {
      console.log(AppService.loggedIn);
      if(AppService.loggedIn === true){
        this.loggedIn = true;
      }else {
        this.loggedIn = false;
      }
    },

    closeMediaModal() {
      this.backgroundModal = false;
    },

    closeLogoModal() {
      this.logoModal = false;
    },

    updateMonitorBackground(url) {
      this.background = url;
    },

    updateLogo(url) {
      this.selectedLogo = url;
    },

    speakText() {
      if ('speechSynthesis' in window) {
        const speech = new SpeechSynthesisUtterance(this.screenDisplay);

        // Get a list of available voices
        const voices = window.speechSynthesis.getVoices();

        // Find a female voice (you can adjust this logic based on your needs)
        const femaleVoice = voices.find(voice => voice.name === 'Google UK English Female');

        // Set the voice for the utterance
        if (femaleVoice) {
          speech.voice = femaleVoice;
        }

        // Speak the text
        speechSynthesis.speak(speech);

        this.spokenText = `Speaking: ${this.screenDisplay}`;
      } else {
        alert('Speech synthesis is not supported in this browser.');
      }
    },

    setInformation() {
      if (this.information) {
        this.message = false;
        localStorage.setItem('information', this.information);
      }

    },

    stopInformation() {
      this.information = null;
      localStorage.removeItem('information');
    },

    openScreen1() {
      // URL of the page you want to open in the new window
      const url = '/primary';
      const options = 'width=600,height=400';
      window.open(url, '_blank', options);
    },

    increaseFontSize() {
      let font = localStorage.getItem('font');

      font = parseInt(font);

      // Increment the font size by 1
      font += 1;

      // Store the updated font size back in local storage
      localStorage.setItem('font', font.toString());
    },

    decreaseFontSize() {
      let font = localStorage.getItem('font');
      font = parseInt(font);
      // Decrease the font size by 1, ensuring it doesn't go below 1
      font = Math.max(3, font - 1);

      // Store the updated font size back in local storage
      localStorage.setItem('font', font.toString());
    },

    toggleCamera() {
      if (localStorage.getItem('displayType') === 'imageBackground') {
        localStorage.setItem('displayType', 'cameraDisplay');
        this.backgroundType = 'cameraDisplay';
      } else {
        this.backgroundType = 'imageBackground';
        localStorage.setItem('displayType', 'imageBackground');
      }
    },

    openScreen2() {
      // URL of the page you want to open in the new window
      const url = '/screen_two';
      const options = 'width=600,height=400';
      window.open(url, '_blank', options);
    },

    clearScreen() {
      this.screenDisplay = null;
      localStorage.removeItem('__token')
    },

    pushToSchedule(id, author, title, flash, lyrics) {
      this.lowerMonitor = flash.substr(0, 190) + '...';
      var exists = this.schedules.some(obj => obj.id === id);
      if (!exists) {
        // console.log(lyrics);
        this.schedules.push({id, author, title, lyrics});
      } else {
        this.previewing = title;
        this.selectFromSchedule(id, lyrics);
        // this.screen(lyrics[0]);
      }
    },

    voiceSpeech() {
      this.speak = true;
      this.startRecognition();
    },

    removeFromSchedule(idToRemove) {
      this.schedules = this.schedules.filter(schedule => schedule.id !== idToRemove);
    },

    selectFromSchedule(id, lyrics) {
      this.selectedScheduleId = id;
      this.setPreviews(lyrics);
    },

    pushScripture(text) {
      if (this.lowerMonitor === text) {
        this.screen(text)
      } else {
        this.lowerMonitor = text;
      }
    },

    startRecognition() {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      if (!SpeechRecognition) {
        alert('Speech recognition is not supported in this browser. Please try using a different browser.');
        return;
      }

      const recognition = new SpeechRecognition();
      recognition.lang = 'en-US';

      recognition.onresult = event => {
        this.selectedBook = event.results[0][0].transcript;
        this.transcribedText = event.results[0][0].transcript;
        this.fetchScripture();
      };

      recognition.onerror = event => {
        console.error('Speech recognition error:', event.error);
      };

      recognition.start();
    },

    setPreviews(lyrics) {
      this.previews = lyrics.map(function (data) {
        return data;
      })
    },

    screen(lyrics) {
      this.screenDisplay = lyrics;
      localStorage.setItem('__token', lyrics)
    },

    edit(id, author, title, fullLyrics) {
      this.errorMessage = null;
      this.updateAuthor = author;
      this.updateTitle = title;
      this.updateLyrics = fullLyrics;
      this.setUpdateLyricsId = id;
      this.songManager = 4;
    },

    logOut() {
      AppService.token = null;
    }

  }

}

</script>

<style scoped>
.monitor {
  background-size: cover;
  /* Fit the background image within the container */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Do not repeat the background image */
}
</style>


