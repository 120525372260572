<template>
  <!-- Modal container (hidden by default) -->
  <div v-if="addNew" id="myModal" class="fixed inset-0 z-50 flex items-center justify-center  bg-black bg-opacity-50">
    <!-- Modal content -->
    <div style="width:50%; background: #1b1e21; padding: 8px">
      <div class="flex justify-between items-center mb-4 px-6 py-3 bg-gray-800 text-white">
        <h2 class="text-lg font-bold">Add Song</h2>
        <button @click="addNew = false" class="text-gray-400 hover:text-gray-200 focus:outline-none">
          <svg class="h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 18L18 6M6 6l12 12" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
      <add-song></add-song>
    </div>
  </div>

  <div v-if="edit" id="myModal" class="fixed inset-0 z-50 flex items-center justify-center  bg-black bg-opacity-50">
    <!-- Modal content -->
    <div style="width:50%; background: #1b1e21; padding: 8px">
      <div class="flex justify-between items-center mb-4 px-6 py-3 bg-gray-800 text-white">
        <h2 class="text-lg font-bold">Edit Song</h2>
        <button @click="edit = false; reset()" class="text-gray-400 hover:text-gray-200 focus:outline-none">
          <svg class="h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 18L18 6M6 6l12 12" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
      <edit-song @child-event="fetchSongs" :author-update="selectedTitle" :title-update="selectedTitle" :id="selectedId" :lyrics-update="selectedLyrics"></edit-song>
    </div>
  </div>

  <div @click="this.closeContextMenu()" class="bottom-section-body">
    <!-- This is going to be a dynamic section, it changes based on the selected menu. The toggling can be done with js -->
    <!-- The section created is for the songs menu  -->
    <div class="songs-box">
      <div class="songs-box-menu">
        <div class="songs-box-menu-header">
                  <span class="icon"
                  ><i class="fa fa-search" aria-hidden="true"></i
                  ></span>
          <!-- <h6>A Heart Like Thee</h6> -->
          <input style="font-size: 16px" type="text" ref="searchInput" v-model="searchQuery" @keyup.enter="fetchSongs()"/>
          <i style="cursor: pointer" @click="searchQuery = null; this.$refs.searchInput.focus()" class="fa fa-close"></i>
        </div>
        <div class="songs-box-menu-body">
          <div class="songs-box-menu-body-group">
            <div class="songs-box-menu-body-group-title">
              <h6>SONGS</h6>
            </div>
            <div class="songs-box-menu-body-group-content">
              <p  @click="() => { publicLibrary = true; fetchSongs() }"  style="cursor: pointer" :class="publicLibrary ? 'active' : 'hov'">
                        <span class="icon">
                          <i class="fa fa-book" aria-hidden="true"></i>
                        </span>
                <span class="text">Public Library</span>
              </p>

              <p  @click="() => { publicLibrary = false; fetchSongs() }"  style="cursor: pointer" :class="!publicLibrary ? 'active' : 'hov'">
                        <span class="icon">
                          <i class="fa fa-book" aria-hidden="true"></i>
                        </span>
                <span class="text">Private Library</span>
              </p>
            </div>
          </div>
          <div>
            <div class="accordion">
              <div class="tab">
                <input type="checkbox" name="accordion-1" id="cb2"/>
                <label for="cb2" class="tab__label">COLLECTIONS</label>
                <div class="tab__content">
                  <p>Spirit Chant - Victoria Orenze</p>
                  <p>You are yahweh - Steve Crown</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="accordion">
              <div class="tab">
                <input type="checkbox" name="accordion-2" id="cb3"/>
                <label for="cb3" class="tab__label"
                >MY COLLECTIONS</label
                >
                <div class="tab__content">
                  <p>Thandwa Ndim - The Soil, Thee Legacy</p>
                  <p>I will pray - Ebuka Songs</p>
                  <p>B'Ola - Honour - Sunmisola Agbebi</p>
                  <p>Wonder - Mercy Chinwo</p>
                  <p>Spirit Chant - Victoria Orenze</p>
                  <p>You are yahweh - Steve Crown</p>
                  <p>Thandwa Ndim - The Soil, Thee Legacy</p>
                  <p>I will pray - Ebuka Songs</p>
                  <p>B'Ola - Honour - Sunmisola Agbebi</p>
                  <p>Wonder - Mercy Chinwo</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="songs-box-menu-footer">
          <div class="songs-box-menu-footer-icon">
                    <span class="plus"
                    ><i class="fa fa-plus" aria-hidden="true"></i
                    ></span>
            <span class="down-arrow"
            ><i class="fa fa-angle-down" aria-hidden="true"></i
            ></span>
          </div>
          <div class="songs-box-menu-footer-icon">
                    <span class="cog"
                    ><i class="fa fa-cog" aria-hidden="true"></i
                    ></span>
            <span class="down-arrow"
            ><i class="fa fa-angle-down" aria-hidden="true"></i
            ></span>
          </div>
        </div>
      </div>
      <div class="songs-box-content">
        <div class="songs-box-content-body">
          <table>
            <thead>
            <tr>
              <td>Songs</td>
              <td>Title</td>
              <td>Author</td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="song in songs" :key="song.id">
              <td style="cursor:pointer"
                  @click="emitEvent(song.id, song.author, song.title, song.flashLyrics,  song.lyrics)"
                  @contextmenu.prevent="showContextMenu($event, song)">
                {{ song.flashLyrics }}
              </td>
              <td>{{ song.title }}</td>
              <td>{{ song.author }}</td>

              <!-- Context menu -->
              <div v-if="showMenu" class="context-menu" :style="{ top: menuY + 'px', left: menuX + 'px' }">
                <div v-if="!publicLibrary" @click="editItem()" style="padding: 12px; font-family: 'Segoe UI, Arial, sans-serif',serif;">Edit
                  This Song
                </div>
                <hr/>
                <div v-if="!publicLibrary" @click="confirmDelete" style="padding: 12px; font-family: 'Segoe UI, Arial, sans-serif',serif;">
                  Delete This Song
                </div>
                <div v-if="publicLibrary"  style="padding: 12px; font-family: 'Segoe UI, Arial, sans-serif',serif;">
                  Public Songs Are Not Editable
                </div>
                <!-- Other context menu options -->
              </div>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="songs-box-content-footer">
          <div style="cursor: pointer" @click="addNew = true" class="songs-box-content-footer-icon">
            <span class="plus"><i class="fa fa-plus" aria-hidden="true"></i></span>
          </div>

          <div class="songs-box-content-footer-icon"><span class="cog">
            <i class="fa fa-cog" aria-hidden="true"></i></span>
            <span class="down-arrow"><i class="fa fa-angle-down" aria-hidden="true"></i></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import AppService from "@/Services/AppService";
import AddSong from "@/components/AddSong.vue";
import EditSong from "@/components/EditSong.vue";

export default {
  name: 'SongsPanel',
  components: {EditSong, AddSong},
  data() {
    return {
      publicLibrary: true,
      edit: false,
      selectedId: null,
      selectedTitle: null,
      selectedAuthor: null,
      selectedLyrics: null,
      addNew: false,
      items: [ /* Your table items */],
      showMenu: false,
      menuX: 0,
      menuY: 0,
      selectedItem: null,

      songs: {
        type: Object,
        required: true
      },
      searchQuery: null
    }
  },
  created() {
    this.fetchSongs();
  },
  methods: {
    fetchSongs() {
      axios.get(AppService.endpoint + 'songs?search=' + this.searchQuery + '&publicLibrary='+ this.publicLibrary, {
        headers: {
          'Authorization': `Bearer ${AppService.token}`
        }
      })
          .then(response => {
            // Handle successful response
            this.songs = response.data;
          })
          .catch(error => {
            // Handle error
            console.error('There was an error!', error);
          });
    },

    showContextMenu(event, item) {
      event.preventDefault();
      this.selectedItem = item;
      this.menuX = event.pageX;
      this.menuY = event.pageY;
      this.showMenu = true;
    },

    editItem() {
      this.selectedId = this.selectedItem.id;
      this.selectedAuthor = this.selectedItem.author;
      this.selectedTitle = this.selectedItem.title;
      this.selectedLyrics = this.selectedItem.fullLyrics;
      this.edit = true;
      this.closeContextMenu();
    },

    reset() {
      this.selectedId = null;
      this.selectedTitle = null;
      this.selectedLyrics = null
      this.selectedAuthor = null
    },

    confirmDelete() {
      if (confirm("Are you sure you want to delete '" + this.selectedItem.title + "' ?")) {
        this.deleteItem();
      }
    },

    deleteItem() {
      axios.delete(AppService.endpoint + 'delete/' + this.selectedItem.id, {
        headers: {
          'Authorization': `Bearer ${AppService.token}`
        }
      })
          .then(response => {
            // Handle success response
            console.log('deleted:', response.data);
            this.fetchSongs();
          })
          .catch(error => {
            // Handle error response
            console.error('Error deleting:', error);
          });
      // Handle delete action
      console.log("Delete item:", this.selectedItem);
      this.closeContextMenu();
    },
    closeContextMenu() {
      this.showMenu = false;
    },

    emitEvent(id, author, title, flashLyrics, lyrics) {
      this.$emit('child-event', id, author, title, flashLyrics, lyrics);
    }
  }
}
</script>

<style scoped>
.context-menu {
  position: fixed;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  top: 30%;
  cursor: pointer;
  z-index: 1000; /* Ensure it's above other elements */
}

.context-menu div:hover {
  background-color: lightgray; /* Change background color on hover */
}
</style>