// sharedService.js
import { ref } from 'vue';

const token = ref(null);

const loggedIn = ref(false);

// const development = 'http://127.0.0.1:8000/api/';
 const live= 'https://ojamiapi.com/api/'

const endpoint = live;

const loginEndpoint = endpoint + 'login';

const registerEndpoint = endpoint + 'register';

// Watch for changes in token

export default {
    token,
    endpoint,
    loginEndpoint,
    loggedIn,
    registerEndpoint
};
