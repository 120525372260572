<template>
  <form class="p-3">
    <div class="mb-4">
      <label class="block text-gray-400 text-sm font-bold mb-2" for="title">
        Author
      </label>
      <input v-model="author" id="author" name="author"
             class="my_input shadow appearance-none border rounded w-full py-2 px-3 text-gray-400 leading-tight focus:outline-none focus:shadow-outline"
             type="text">
    </div>
    <div class="mb-4">
      <label class="block text-gray-400 text-sm font-bold mb-2" for="title">
        Song Title
      </label>
      <input v-model="title" id="title" name="title"
             class="my_input shadow appearance-none border rounded w-full py-2 px-3 text-gray-400 leading-tight focus:outline-none focus:shadow-outline"
             type="text">
    </div>
    <div class="mb-6">
      <label class="block text-gray-400 text-sm font-bold mb-2" for="lyrics">
        Lyrics
      </label>
      <textarea
          v-model="lyrics"
          id="lyrics"
          name="lyrics"
          class="my_input shadow appearance-none border rounded w-full py-2 px-3 text-gray-400 leading-tight focus:outline-none focus:shadow-outline"
          style="height: 351px; white-space: pre-wrap; font-family: monospace; font-size: 14px; line-height: 1.5;"
      ></textarea>

    </div>
    <div class="flex items-center justify-between">
      <p class="text-xs italic"><i class="text-red-500" v-if="error">Unable to update. Please fill out all fields.</i> <i
          class="text-green-500" v-if="success">Your Songs have been successfully updated.</i></p>
      <button @click.prevent="save"
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline rounded-sm text-xs"
              type="button">
        Save
      </button>
    </div>
  </form>
</template>

<script>
import axios from 'axios';
import AppService from "@/Services/AppService";

export default {
  name: 'EditSong',
  props: {
    id: {
      required: true
    },
    titleUpdate: {
      type: String,
      required: true
    },
    lyricsUpdate: {
      type: String,
      required: true
    },
    authorUpdate: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      author: this.authorUpdate,
      title: this.titleUpdate,
      lyrics: this.lyricsUpdate,
      error: false,
      success: false
    };
  },
  methods: {
    emitEvent() {
      this.$emit('child-event');
    },
    save() {
      this.success = false;
      // Check if title and lyrics are not empty
      if (this.title.trim() === '' || this.lyrics.trim() === '') {
        this.error = true;
      } else {
        // Prepare data to send to the API
        const data = {
          title: this.title,
          author: this.author,
          lyrics: this.lyrics,
          id:this.id
        };

        // Send POST request to the API endpoint using Axios
        axios.put(AppService.endpoint + 'update', data,{
          headers: {
            'Authorization': `Bearer ${AppService.token}`
          }
        })
            .then(response => {
              // Handle success response
              console.log('Song saved successfully:', response.data);
              this.emitEvent();

              // Reset error state and clear form fields
              this.error = false;
              this.success = true;
            })
            .catch(error => {
              // Handle error response
              console.error('Error saving song:', error);

              // Optionally, display an error message or perform error handling
            });
      }
    }
  }
};
</script>
