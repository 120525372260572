import { createApp } from 'vue';
import App from './App.vue';
import { createRouter, createWebHistory } from 'vue-router';

// Import your components for routing
import Home from './views/HomeView.vue';
import Primary from './views/ScreenOneView.vue';
import Secondary from './views/ScreenTwoView.vue';
import AddSong from './components/AddSong.vue';
import './registerServiceWorker'

// Create router instance
const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/primary',
            name: 'screen_one',
            component: Primary
        },
        {
            path: '/screen_two',
            name: 'screen_two',
            component: Secondary
        },
        {
            path: '/add_song',
            name: 'add_song',
            component: AddSong
        },
    ]
});

// Create Vue app instance
const app = createApp(App);

// Use Vue Router
app.use(router);

// Mount the app to the DOM
app.mount('#app');
