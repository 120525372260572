<template>
  <div class="camera-container">
    <video ref="video" autoplay></video>
  </div>

    <p style="margin-bottom:7px;
        position: absolute; bottom: 0;color:
        white; font-size: 60px;text-shadow:#000 14px 14px 14px,
        #000 1px 1px 1px,   #000 80px 80px 80px;
        width: 100%;
        background: darkblue; opacity: 0.8;">{{ lyrics }}
    </p>

</template>

<script>
export default {
  data() {
    return {
      lyrics: null
    }
  },
  mounted() {
    this.startCamera();
    this.todo();
  },
  methods: {
    async startCamera() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({video: true});
        this.$refs.video.srcObject = stream;
      } catch (error) {
        console.error('Error accessing the camera:', error);
      }
    },
    todo: function(){
      this.intervalid1 = setInterval(function(){
        this.changes = ((Math.random() * 100).toFixed(2))+'%';
        this.lyrics = localStorage.getItem('__token');
      }.bind(this), 1000);
    }
  }

}
</script>

<style scoped>
.camera-container {
  width: 100%;
  height: 100%;
  position: relative;
}

video {
  width: 100%;
  height: 100%;
}
</style>
