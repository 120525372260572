<template>
  <div v-if="displayType === 'cameraDisplay'" class="camera-container">
    <video ref="video" autoplay></video>
  </div>
  <p v-if="displayType === 'cameraDisplay'" class="text-center animate__animated animate__backInRight"
     :style="{
     marginBottom: information ? '70px' : '7px',
     position: 'fixed',
     bottom: '0',
     color: 'white',
     fontSize: '60px',
     textShadow: '14px 14px 14px rgba(0, 0, 0, 0.5), 1px 1px 1px rgba(0, 0, 0, 0.5), 80px 80px 80px rgba(0, 0, 0, 0.5)',
     width: '100%',
     backgroundImage: 'url(' + background + ')',
     opacity: '0.8'
   }">
    {{ lyrics }}
  </p>

  <div v-if="displayType === 'imageBackground'" class="screen_one fullscreen-div"
       :style="{ 'background-image': 'url(' + background + ')' }">
    <div id="screen row">
      <p class="shade"
         :style="{ textAlign: 'center',  color: 'white', whiteSpace: 'pre-line', fontSize: fontSize + 'px' }"
         v-html="lyrics"></p>
    </div>
  </div>

  <marquee v-if="information" behavior="scroll" direction="left"
           style="position: fixed; bottom: 0; width: 100%; background-color:blue; color: #fff; padding: 10px; font-size: 30px">
    {{ information }}
  </marquee>

  <img v-if="logo !== 'null'" style="position: fixed" ref="draggableItem" width="40" :src="logo" alt="Logo" class="logo">
  <img v-if="logo === 'null'" style="position: fixed; display: none" ref="draggableItem" width="40" :src="logo" alt="Logo" class="logo">
</template>

<script>

export default {
  name: 'screen_one',
  el: '#screen',

  data() {
    return {
      logo: null,
      information: null,
      lyrics: null,
      background: null,
      fontSize: null,
      displayType: null
    }
  },

  mounted() {
    this.makeDraggable();
    this.startCamera();
    // Retrieve font initial values
    const storedFontSize = localStorage.getItem('font');
    this.fontSize = parseInt(storedFontSize); // Update font size if stored value exists

    // get initial values
    this.lyrics = localStorage.getItem('__token');
    this.displayType = localStorage.getItem('displayType');
    this.background = localStorage.getItem('background');
    this.information = localStorage.getItem('information');
    this.logo = localStorage.getItem('logo');

    // Listen for storage events to detect changes made in other tabs/windows
    window.addEventListener('storage', (event) => {
      if (event.key === '__token') {
        this.lyrics = event.newValue;
      }

      if (event.key === 'font') {
        this.fontSize = parseInt(event.newValue); // Update font size if 'font' key changes
      }

      if (event.key === 'displayType') {
        this.startCamera();
        this.displayType = event.newValue;
      }

      if (event.key === 'information') {
        this.information = event.newValue;
      }

      if (event.key === 'logo') {
        this.logo = event.newValue;
      }

      if (event.key === 'background') {
        this.background = event.newValue;
      }
    });
  },

  methods: {
    makeDraggable() {
      const item = this.$refs.draggableItem;

      let isDragging = false;
      let offsetX = 0;
      let offsetY = 0;

      item.addEventListener('mousedown', (event) => {
        isDragging = true;
        offsetX = event.offsetX;
        offsetY = event.offsetY;
      });

      document.addEventListener('mousemove', (event) => {
        if (isDragging) {
          item.style.left = `${event.clientX - offsetX}px`;
          item.style.top = `${event.clientY - offsetY}px`;
        }
      });

      document.addEventListener('mouseup', () => {
        isDragging = false;
      });
    },
    getInformation() {
      this.information = localStorage.getItem('information');
    },
    async startCamera() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({video: true});
        this.$refs.video.srcObject = stream;
      } catch (error) {
        console.error('Error accessing the camera:', error);
      }
    },
  }
}
</script>

<style>
/* Set the HTML and body to fill the entire viewport */
.full {
  height: 100%;
  margin: 0;
  overflow: hidden; /* Prevent default body scroll */
}

/* Set the fullscreen div's styles */
.fullscreen-div {
  background-size: cover; /* Stretch the background to cover the container */
  background-position: center; /* Center the background image */

  height: 100vh; /* 100% of the viewport height */
  width: 100vw; /* 100% of the viewport width */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.text-with-border {
  color: white; /* Text color */
  text-shadow: -1px -1px 0 black,
  1px -1px 0 black,
  -1px 1px 0 black,
  1px 1px 0 black; /* Adjust values to increase or decrease the border thickness */
}

/* Using outline */
.text-with-border {
  color: white; /* Text color */
  -webkit-text-stroke: 1px black; /* Adjust thickness as needed */
  text-stroke: 1px black; /* Adjust thickness as needed */
}

.camera-container {
  width: 100%;
  height: 100%;
  position: relative;
}

video {
  width: 100%;
  height: 100%;
}

.shade {
  color: white; /* Set text color */
  text-shadow: -2px -2px 0 #000,
  2px -2px 0 #000,
  -2px 2px 0 #000,
  2px 2px 0 #000;
}

.draggable-item {
  position: absolute;
  cursor: move;
  background-color: lightblue;
  padding: 10px;
}

.logo {
  position: absolute;
  top: 10px; /* Adjust the top spacing */
  left: 10px; /* Adjust the left spacing */
  width: 90px; /* Adjust the width of the logo */
  height: auto; /* Maintain aspect ratio */
}

</style>