<template>
  <!-- Modal container (hidden by default) -->
  <div v-if="addNew" id="myModal" class="fixed inset-0 z-50 flex items-center justify-center  bg-black bg-opacity-50">
    <!-- Modal content -->
    <div style="width:50%" class="bg-gray-900">
      <div class="flex justify-between items-center mb-4 px-6 py-3 bg-gray-800 text-white">
        <h2 class="text-lg font-bold">Add Song</h2>
        <button @click="addNew = false" class="text-gray-400 hover:text-gray-200 focus:outline-none">
          <svg class="h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 18L18 6M6 6l12 12" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
      <add-song></add-song>
    </div>
  </div>

  <div v-if="edit" id="myModal" class="fixed inset-0 z-50 flex items-center justify-center  bg-black bg-opacity-50">
    <!-- Modal content -->
    <div style="width:50%" class="bg-gray-900">
      <div class="flex justify-between items-center mb-4 px-6 py-3 bg-gray-800 text-white">
        <h2 class="text-lg font-bold">Edit Song</h2>
        <button @click="edit = false; reset()" class="text-gray-400 hover:text-gray-200 focus:outline-none">
          <svg class="h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 18L18 6M6 6l12 12" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
      <edit-song :title-update="selectedTitle" :id="selectedId" :lyrics-update="selectedLyrics"></edit-song>
    </div>
  </div>

  <div @click="this.closeContextMenu()" class="bottom-section-body">
    <!-- This is going to be a dynamic section, it changes based on the selected menu. The toggling can be done with js -->
    <!-- The section created is for the songs menu  -->
    <div class="songs-box">
      <div class="songs-box-menu">
        <div class="songs-box-menu-header">
                  <span class="icon"
                  ><i class="fa fa-search" aria-hidden="true"></i
                  ></span>
          <!-- <h6>A Heart Like Thee</h6> -->
          <input style="font-size: 16px" ref="searchInput" type="text" v-model="searchQuery" @input="filterBooks" @keyup.enter="fetchSongs()"/>
          <i :style="speak ? 'color:red ' : 'cursor: pointer'" class="fa fa-microphone" @click="voiceSpeech"></i>
          <i style="cursor: pointer" @click="searchQuery = null; this.$refs.searchInput.focus()" class="fa fa-close"></i>
        </div>

        <div v-if="hideFilter" class="text-left text-white" style="padding-left: 22px; font-family: 'Segoe UI, Arial, sans-serif',serif; cursor: pointer">
          <ol>
            <li  v-for="(book, index) in filteredBooks" :key="index" class="hover:bg-blue-800 cursor-pointer p-2" @click="setSearched(book)">
              {{ book }}
            </li>
          </ol>
        </div>

        <div class="songs-box-menu-body">
          <div class="songs-box-menu-body-group">
            <div class="songs-box-menu-body-group-title">
              <h6> VERSIONS</h6>
            </div>
            <div class="songs-box-menu-body-group-content">
              <p class="active">
                        <span class="icon">
                          <i class="fa fa-book" aria-hidden="true"></i>
                        </span>
                <span class="text">King James</span>
              </p>

              <p style="cursor: pointer" class="hov">
                        <span class="icon">
                          <i class="fa fa-book" aria-hidden="true"></i>
                        </span>
                <span class="text" style="cursor: pointer">NIV</span>
              </p>
            </div>
          </div>
        </div>
        <div class="songs-box-menu-footer">
          <div class="songs-box-menu-footer-icon">
                    <span class="plus"
                    ><i class="fa fa-plus" aria-hidden="true"></i
                    ></span>
            <span class="down-arrow"
            ><i class="fa fa-angle-down" aria-hidden="true"></i
            ></span>
          </div>
          <div class="songs-box-menu-footer-icon">
                    <span class="cog"
                    ><i class="fa fa-cog" aria-hidden="true"></i
                    ></span>
            <span class="down-arrow"
            ><i class="fa fa-angle-down" aria-hidden="true"></i
            ></span>
          </div>
        </div>
      </div>
      <div class="songs-box-content">
        <div class="songs-box-content-body">
          <table>
            <thead>
            <tr>
              <td>Text</td>
              <td>Chapter</td>
              <td>Verse</td>
            </tr>
            </thead>
            <tbody v-if="Object.keys(bible).length > 2">
            <tr style="cursor:pointer" v-for="(book, index) in bible" :key="index" @click="selected = index; emitEvent(book.verseText)" :class="selected === index? 'bg-blue-800': ''">
              <td>{{ book.startVerse }}) {{ book.verseText }}</td>
              <td>{{ book.chapter }}</td>
              <td>{{ book.startVerse }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="songs-box-content-footer">
          <div class="songs-box-content-footer-icon">
                    <span class="plus"
                    ><i class="fa fa-plus" aria-hidden="true"></i
                    ></span>
          </div>
          <div class="songs-box-content-footer-icon">
                    <span class="cog"
                    ><i class="fa fa-cog" aria-hidden="true"></i
                    ></span>
            <span class="down-arrow"
            ><i class="fa fa-angle-down" aria-hidden="true"></i
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import AppService from "@/Services/AppService";
import AddSong from "@/components/AddSong.vue";
import EditSong from "@/components/EditSong.vue";

export default {
  name: 'BiblePanel',
  components: {EditSong, AddSong},
  data() {
    return {
      speak:false,
      hideFilter: true,
      selected:null,
      bibleBooks: [
        'Genesis ',
        'Exodus ',
        'Leviticus ',
        'Numbers ',
        'Deuteronomy ',
        'Joshua ',
        'Judges ',
        'Ruth ',
        '1 Samuel ',
        '2 Samuel ',
        '1 Kings ',
        '2 Kings ',
        '1 Chronicles ',
        '2 Chronicles ',
        'Ezra ',
        'Nehemiah ',
        'Esther ',
        'Job ',
        'Psalms ',
        'Proverbs ',
        'Ecclesiastes ',
        'Song of solomon ',
        'Isaiah ',
        'Jeremiah ',
        'Lamentations ',
        'Ezekiel ',
        'Daniel ',
        'Hosea ',
        'Joel ',
        'Amos ',
        'Obadiah ',
        'Jonah ',
        'Micah ',
        'Nahum ',
        'Habakkuk ',
        'Zephaniah ',
        'Haggai ',
        'Zechariah ',
        'Malachi ',
        'Matthew ',
        'Mark ',
        'Luke ',
        'John ',
        'Acts ',
        'Romans ',
        '1 Corinthians ',
        '2 Corinthians ',
        'Galatians ',
        'Ephesians ',
        'Philippians ',
        'Colossians ',
        '1 Thessalonians ',
        '2 Thessalonians ',
        '1 Timothy ',
        '2 Timothy ',
        'Titus ',
        'Philemon ',
        'Hebrews ',
        'James ',
        '1 Peter ',
        '2 Peter ',
        '1 John ',
        '2 John ',
        '3 John ',
        'Jude ',
        'Revelation '
      ],
      edit: false,
      selectedId: null,
      selectedTitle: null,
      selectedLyrics: null,
      addNew: false,
      items: [ /* Your table items */],
      showMenu: false,
      menuX: 0,
      menuY: 0,
      selectedItem: null,
      bible: {
        type: Object,
        required: true
      },
      searchQuery: ''
    }
  },
  created() {
    this.fetchSongs();
  },
  computed: {
    filteredBooks() {
      return this.filterBooks(this.searchQuery);
    }
  },
  methods: {
    filterBooks(searchTerm) {
      if(this.searchQuery){
        this.hideFilter = true;
        searchTerm = this.searchQuery.toLowerCase();
        return this.bibleBooks.filter(book => {
          return book.toLowerCase().includes(searchTerm);
        });
      }
    },


    setSearched(book) {
      this.searchQuery = book; // Set the searchQuery to the selected book
      this.hideFilter = false;
      this.$refs.searchInput.focus(); // Focus on the search input element
    },

    fetchSongs() {
      axios.get(AppService.endpoint + 'bible/' + this.searchQuery, {
        headers: {
          'Authorization': `Bearer ${AppService.token}`
        }
      })
          .then(response => {
            // Handle successful response
            this.bible = response.data;
          })
          .catch(error => {
            // Handle error
            console.error('There was an error!', error);
          });
    },

    voiceSpeech() {
      this.speak = true;
      this.startRecognition();
    },

    startRecognition() {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      if (!SpeechRecognition) {
        alert('Speech recognition is not supported in this browser. Please try using a different browser.');
        return;
      }

      const recognition = new SpeechRecognition();
      recognition.lang = 'en-US';

      recognition.onresult = event => {
        this.searchQuery = event.results[0][0].transcript;
        this.transcribedText = event.results[0][0].transcript;
        this.fetchSongs();
        this.speak = false;
      };

      recognition.onerror = event => {
        console.error('Speech recognition error:', event.error);
      };

      recognition.start();
    },

    showContextMenu(event, item) {
      event.preventDefault();
      this.selectedItem = item;
      this.menuX = event.pageX;
      this.menuY = event.pageY;
      this.showMenu = true;
    },

    editItem() {
      this.selectedId = this.selectedItem.id;
      this.selectedTitle = this.selectedItem.title;
      this.selectedLyrics = this.selectedItem.fullLyrics;
      this.edit = true;
      this.closeContextMenu();
    },

    reset() {
      this.selectedId = null;
      this.selectedTitle = null;
      this.selectedLyrics = null
    },

    confirmDelete() {
      if (confirm("Are you sure you want to delete '" + this.selectedItem.title + "' ?")) {
        this.deleteItem();
      }
    },

    deleteItem() {
      axios.delete(AppService.endpoint + 'delete/' + this.selectedItem.id)
          .then(response => {
            // Handle success response
            console.log('deleted:', response.data);
            this.fetchSongs();
          })
          .catch(error => {
            // Handle error response
            console.error('Error deleting:', error);
          });
      // Handle delete action
      console.log("Delete item:", this.selectedItem);
      this.closeContextMenu();
    },
    closeContextMenu() {
      this.showMenu = false;
    },

    emitEvent(text) {
      this.$emit('showBibleText', text);
    }
  }
}
</script>

<style scoped>
.context-menu {
  position: fixed;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  top: 30%;
  cursor: pointer;
  z-index: 1000; /* Ensure it's above other elements */
}

.context-menu div:hover {
  background-color: lightgray; /* Change background color on hover */
}
</style>