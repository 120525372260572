<template>
  <div id="myModal" class="fixed inset-0 z-50 flex items-center justify-center  bg-black bg-opacity-80">
    <div @click="this.showMenu = false;" style="width:50%; background: #1b1e21; padding: 8px">
      <div class="flex justify-between items-center mb-4 px-6 py-3 bg-gray-800 text-white">
        <h2 class="text-lg font-bold">Set Church Logo &nbsp;
          <i v-if="!loading && !defaultImage" style="font-size: 14px; cursor: pointer" class="fa fa-plus" aria-hidden="true" @click="openFileInput"></i>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Default <input style="cursor: pointer" type="checkbox" :disabled="defaultImage" :checked="defaultImage" @click="defaultImage = true; getImages()">
          &nbsp;&nbsp;&nbsp;Custom <input style="cursor: pointer" type="checkbox" :checked="!defaultImage" @click="defaultImage = false; getImages()" :disabled="!defaultImage" >
        </h2>
        <button @click="closeMedia" class="text-gray-400 hover:text-gray-200 focus:outline-none">
          <svg class="h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 18L18 6M6 6l12 12" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </button>
      </div>

      <div  v-if="loading" align="center">
        <br/><br/>
        <div class="w-12 h-12 border-t-4 border-blue-500 rounded-full animate-spin"></div>
        <br/><br/>
      </div>

      <div v-if="images.length < 1 && !loading" align="center">
        <br/>
        <p style="color: white">No Logo Available</p>
        <br/>
      </div>

      <!-- Hidden file input -->
      <input type="file" @change="handleFileChange" ref="fileInput" style="display: none">
      <div class="grid grid-cols-5 gap-5">
        <div v-for="(image, index) in images" :key="index" class="relative">
          <img :src="image.url" :alt="image.alt" class="w-full h-40 rounded-md cursor-pointer" :style="selectedId === image.id ? 'border: 1px solid red;' : ''"
               @click="openImage(image.url, image.id)" @contextmenu.prevent="showContextMenu($event, image)">
          <div v-if="showMenu && !defaultImage" class="context-menu" :style="{ top: menuY + 'px', left: menuX + 'px' }">
            <div style="padding: 12px">Send To Schedule</div>
            <hr/>
            <div @click="deleteImage" style="padding: 12px">Delete This Image</div>
            <!-- Other context menu options -->
          </div>
        </div>
      </div>

      <div align="right">
        <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline rounded-sm text-xs" @click="removeLogo">Remove logo</button>
      </div>
    </div>
  </div>


  <!--  <i style="font-size: 12px" class="fa fa-plus" aria-hidden="true"></i>-->
</template>

<script>


import axios from "axios";
import AppService from "@/Services/AppService";

export default {
  name:'ChurchLogo',
  computed: {
    AppService() {
      return AppService
    }
  },
  data() {
    return {
      defaultImage: true,
      selectedId:null,
      loading: false,
      selectedFile: null,
      showMenu: false,
      menuX: 0,
      menuY: 0,
      selectedItem: null,
      images: []
    };
  },

  mounted() {
    this.getImages();
  },

  methods: {
    removeLogo(){
      localStorage.setItem('logo', null);
      this.emitEvent(null);
    },

    showContextMenu(event, item) {
      event.preventDefault();
      this.selectedItem = item;
      this.menuX = event.pageX;
      this.menuY = event.pageY;
      this.showMenu = true;
    },

    openFileInput() {
      // Programmatically trigger the file input when the icon is clicked
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      // Handle file selection when a file is chosen
      const selectedFile = event.target.files[0];
      // You can store the selected file or perform other operations here
      this.selectedFile = selectedFile;
      this.uploadImage();
    },
    uploadImage() {
      // Perform the upload logic here, using the selectedFile if needed
      if (this.selectedFile) {
        this.loading = true;
        // Example: Assuming you are sending the file to a server using Axios
        const formData = new FormData();
        formData.append('file', this.selectedFile);
        formData.append('type', 2); // background logos

        // Make an HTTP request (e.g., with Axios) to upload the file
        // Example Axios request:
        axios.post(AppService.endpoint + 'upload_image', formData, {
          headers: {
            'Authorization': `Bearer ${AppService.token}`
          }
        })
            .then(response => {
              console.log('File uploaded successfully', response);
              this.getImages()
            })
            .catch(error => {
              console.error('Error uploading file', error);
            });

        // Reset selectedFile after upload if needed
        this.selectedFile = null;
      } else {
        console.error('No file selected for upload');
      }
    },

    getImages(){
      this.images = [];
      this.loading = true;
      axios.get(AppService.endpoint + 'get_image/2/'+this.defaultImage, {
        headers: {
          'Authorization': `Bearer ${AppService.token}`
        }
      })
          .then(response => {
            this.loading = false;
            this.images = response.data;
          })
          .catch(error => {
            this.loading = false;
            console.error('Error uploading file', error);
          });
    },

    deleteImage(){
      this.loading = true;
      axios.delete(AppService.endpoint + 'delete_image/'+ this.selectedItem.id, {
        headers: {
          'Authorization': `Bearer ${AppService.token}`
        }
      })
          .then(response => {
            this.loading = false;
            console.log(response);
            this.getImages();
          })
          .catch(error => {
            this.loading = false;
            console.error('Error uploading file', error);
          });
    },

    openImage(url, id) {
      this.selectedId = id;
      localStorage.setItem('logo', url);
      this.emitEvent(url);
    },
    emitEvent(url) {
      console.log(url)
      this.$emit('logo-event', url);
    },

    closeMedia() {
      this.$emit('close-logo');
    }
  },
};
</script>

<style scoped>
.context-menu {
  font-family: 'Segoe UI, Arial, sans-serif', serif;
  position: fixed;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  top: 30%;
  cursor: pointer;
  z-index: 1000; /* Ensure it's above other elements */
}

.context-menu div:hover {
  background-color: lightgray; /* Change background color on hover */
}
</style>